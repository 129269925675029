import { Fragment, ReactElement } from "react";
import VaccineRecordTabs from "./Tabs";
interface VaccineRecordProps {
  selectedTabValue: number;
  handleChangeTab: any;
  shareVaccineRecordsVerified: any;
}
export const VaccineRecord = ({ selectedTabValue, handleChangeTab, shareVaccineRecordsVerified }: VaccineRecordProps): ReactElement => {
  return (
    <Fragment>
      <VaccineRecordTabs value={selectedTabValue} handleChange={handleChangeTab} shareVaccineRecordsVerified={shareVaccineRecordsVerified} />
    </Fragment>
  );
};
