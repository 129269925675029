import { ReactElement } from "react";
import { DoneIcon, TextIcon } from "../../icons";

export const CustomStepper = ({ activeStep, steps }: { activeStep: number; steps: string[] }): ReactElement => {
  return (
    <div className="flex items-center mr-[34px] gap-[14px]">
      {steps?.map((step, idx) => {
        return (
          <div className="w-[50%] grid grid-cols-[auto_1fr] items-center gap-[7px]">
            <div className="flex items-center gap-[14px]">
              {activeStep > idx ? (
                <DoneIcon />
              ) : (
                <TextIcon
                  // className={activeStep > idx ? "light-color" : "dark-color"}
                  number={(idx + 1).toString()}
                />
              )}
              <span className="text-[#0F5175] text-[18px] font-[500]">{step}</span>
            </div>
            <div className="h-[1px] border-t-[1px] border-t-[#B4B8BB] border-solid w-full" />
          </div>
        );
      })}
    </div>
  );
};
