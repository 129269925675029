import { ReactElement, useEffect, useState } from "react";
import "./VaccineRecords.css";
import { getShareVaccineRecords } from "../../redux/actionCreators";
import { useSelector } from "react-redux";
import { AppState } from "../../redux/store";
import { DashboardLayout } from "../../components";
import InjectionIcon from "../../icons/InjectionIcon.icon";
import { VaccineRecord } from "../../sections/VaccineRecord";

export const VaccineRecords = (): ReactElement => {
  const { userData } = useSelector<AppState, AppState["Login"]>(({ Login }) => Login);
  const [selectedTabValue, setSelectedTabValue] = useState(0);
  const [shareVaccineRecordsVerified, setShareVaccineRecordsVerified] = useState([]);
  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTabValue(newValue);
  };
  useEffect(() => {
    if (!userData?.email) return;
    (async () => {
      try {
        const res = await getShareVaccineRecords({ email: userData.email });
        setShareVaccineRecordsVerified(res?.shareVaccineRecordsVerified);
      } catch (error) {
        console.error("Error in getShareVaccineRecords:", error);
      }
    })();
  }, [userData, setShareVaccineRecordsVerified]);
  return (
    <DashboardLayout>
      <div className="flex items-center justify-between mb-[5px]">
        <h2 className="text-[24px] text-primary-main font-[600]">{"Your Vaccine Card"}</h2>
      </div>
      <div className="view-vaccine-card_">
        <div className="Card">
          <div className="Rectangle-133">
            <span className="Mark-Grammy">{userData.name}</span>
            <br />
            <span className="Nov-13-1991">{userData.email}</span>
            <div className="Mask-Group">
              <div className="Group-338">
                <div className="Vector">
                  <InjectionIcon />
                </div>
              </div>
            </div>
          </div>
        </div>
        <VaccineRecord selectedTabValue={selectedTabValue} handleChangeTab={handleChangeTab} shareVaccineRecordsVerified={shareVaccineRecordsVerified} />
      </div>
    </DashboardLayout>
  );
};
