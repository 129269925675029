import { Fragment, useState } from "react";
import { AppState } from "../../redux/store";
import { useSelector } from "react-redux";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionActions, AccordionSummary, AccordionDetails } from "@mui/material";
import { ShareEmailModal, VAButton } from "../../components";
import { ShareIcon } from "../../icons/Share.icon";
import VAAlert from "../../components/Alert/Alert";
import { AlertType } from "../../shared/constants/AppConst";
interface AccordianProps {
  data: any;
}
export default function TestRecordAccordian({ data }: AccordianProps) {
  const { userData } = useSelector<AppState, AppState["Login"]>(({ Login }) => Login);
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState<AlertType | null>(null);
  const [shareFormData, setShareFormData] = useState<any>({});
  const onClickShare = async (shareFormDataObj: any) => {
    shareFormDataObj.patient_email = userData.email;
    shareFormDataObj.patient_name = userData.name;
    setShareFormData(shareFormDataObj);
    setOpen(true);
  };

  return (
    <Fragment>
      {data?.map((testRecord: any, idx: number) => {
        return (
          <Accordion key={idx}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel${idx}-content`} id={`panel${idx}-header`}>
              <b>{testRecord.vaccine}</b>
            </AccordionSummary>
            <Fragment>
              <AccordionDetails>
                <div className="mt-[10px]">
                  <p className="text-[#888A8C]">
                    <span>Test:</span>
                    <span className="text-[#000000]">{testRecord.vaccine}</span>
                  </p>
                  <p className="text-[#888A8C]">
                    <span>Manufacturer:</span>
                    <span className="text-[#000000]">{testRecord.manufacturer}</span>
                  </p>
                  <p className="text-[#888A8C]">
                    Verified By: <span className="text-[#000000]">{`${testRecord.verifier_name}`}</span>
                  </p>
                  <p className="text-[#888A8C]">
                    Provider Address: <span className="text-[#000000]">{`${testRecord.biz_branch_address}`}</span>
                  </p>
                  <p className="text-[#888A8C]">
                    <span>Specimen Source:</span>
                    <span className="text-[#000000]">{testRecord.specimen_source}</span>
                  </p>
                  <p className="text-[#888A8C]">
                    <span>Test Type:</span>
                    <span className="text-[#000000]">{testRecord.test_type}</span>
                  </p>
                  {testRecord.test_type === "Categorical" && (
                    <p className="text-[#888A8C]">
                      <span>Result:</span>
                      <span className="text-[#000000]">{testRecord.categorical_result}</span>
                    </p>
                  )}
                  {testRecord.test_type === "Numerical" && (
                    <p className="text-[#888A8C]">
                      <span>Result:</span>
                      <span className="text-[#000000]">{`${testRecord.numerical_value} ${testRecord.numerical_result}`}</span>
                    </p>
                  )}
                  <p className="text-[#888A8C]">
                    <span>Test Date:</span>
                    <span className="text-[#000000]">{testRecord.test_date}</span>
                  </p>
                  <p className="text-[#888A8C]">
                    <span>Test Date Resulted:</span>
                    <span className="text-[#000000]">{testRecord.test_date_resulted}</span>
                  </p>
                  <p className="text-[#888A8C]">
                    <span>Notes:</span>
                    <span className="text-[#000000]">{testRecord.verify_note}</span>
                  </p>
                </div>
              </AccordionDetails>
              <AccordionActions>
                <VAButton size="small" dark text="Share" icon={<ShareIcon />} onClick={() => onClickShare(testRecord)} />
              </AccordionActions>
            </Fragment>
          </Accordion>
        );
      })}
      <ShareEmailModal open={open} setOpen={setOpen} shareClick={onClickShare} data={shareFormData} setAlertMessage={setAlertMessage} />
      {alertMessage !== null ? <VAAlert alertMessage={alertMessage} setAlertMessage={setAlertMessage} /> : null}
    </Fragment>
  );
}
