import { Fragment, ReactElement } from "react";
import TestRecordTabs from "./Tabs";
interface TestRecordProps {
  selectedTabValue: number;
  handleChangeTab: any;
  shareTestRecordsVerified: any;
}
export const TestRecord = ({ selectedTabValue, handleChangeTab, shareTestRecordsVerified }: TestRecordProps): ReactElement => {
  return (
    <Fragment>
      <TestRecordTabs value={selectedTabValue} handleChange={handleChangeTab} shareTestRecordsVerified={shareTestRecordsVerified} />
    </Fragment>
  );
};
