import { ReactElement, useState } from "react";
import { isSameDay } from "date-fns";
import VAAlert from "../Alert/Alert";
import { AlertType } from "../../shared/constants/AppConst";

interface DayCardProps {
  date: any;
  events: any;
  selectedDate: any;
  scheduleData: any;
  handleOpenPopover: any;
}

export const DayCard = ({ date, selectedDate, scheduleData, handleOpenPopover }: DayCardProps): ReactElement => {
  const [show, setShow] = useState(false);
  const [alertMessage, setAlertMessage] = useState<AlertType | null>(null);
  //Extracts day from date object
  const day = date.getDate();

  //Checks if current day matches dat
  const sameDayCheck = scheduleData.some((data: any) => isSameDay(new Date(data.date), new Date(date)));
  const isoDate = new Date(selectedDate).getDate();

  const currentDayStyles =
    sameDayCheck === true
      ? selectedDate !== null && day === isoDate
        ? {
            backgroundColor: "#45818e",
            color: "white",
          }
        : {
            backgroundColor: "#F48744",
            color: "white",
          }
      : {
          backgroundColor: "#ffffff",
        };

  const clickMethod = () => {
    // Get the current date and set the time to midnight for comparison
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Set current time to midnight

    // Create a new date object using the selected day, current month, and year
    const selectedDate = date;
    selectedDate.setHours(0, 0, 0, 0); // Set selected date to midnight
    // Check if the selected date is before today
    if (selectedDate < today) {
      setAlertMessage({ type: "info", description: "Appointment cannot be scheduled for past dates.", open: true });
      return;
    }

    // Continue with the scheduling logic if the date is valid
    for (let i = 0; i < scheduleData.length; i++) {
      const element = scheduleData[i];
      if (new Date(element.date).toDateString() === selectedDate.toDateString()) {
        setShow(!show);
        handleOpenPopover(element.date);
        return;
      }
    }
  };
  return (
    <div
      {...(currentDayStyles.backgroundColor !== "#ffffff" && { onClick: clickMethod })}
      className={` ${show ? "text-[#FF6666]" : "text-[gray]"} relative flex flex-col items-center justify-center group px-[4px] cursor-pointer `}
      style={currentDayStyles}
    >
      <div className="flex justify-center items-center">
        <span className="mt-1 p-[8px] h-[35px] w-[35px] flex justify-center items-center  text-sm font-semibold ">{day}</span>
      </div>
      {alertMessage !== null ? <VAAlert alertMessage={alertMessage} setAlertMessage={setAlertMessage} /> : null}
    </div>
  );
};
