import { ReactElement, useEffect, useState } from "react";
import { VAButton, CustomStepper, DashboardLayout, FromSelect } from "../../components";
import { useFormFieldsHandleChange } from "../../utils";
import { Link } from "react-router-dom";
import { Back } from "../../icons";
import { getScheduledData, bookAppointment, getTestCategory, getLocationData, getVaccine } from "../../redux/actionCreators/scheduleVaccine";
import { useSelector } from "react-redux";
import { AppState } from "../../redux/store";
// import haversine from 'haversine-distance'
// import Geocode from "react-geocode";
import "react-datepicker/dist/react-datepicker.css";
import { SelectDateAndTime } from "../selectDateAndTime";
import { Button, Grid, Popover } from "@mui/material";
import { AppointmentModal } from "../../components/CustomModal/AppointModal.component";
import { AlertType } from "../../shared/constants/AppConst";
import VAAlert from "../../components/Alert/Alert";
import { createTimeSlots, getFormattedDate } from "../../shared/Util";

export const ScheduleTestAppointment = (): ReactElement => {
  //stepper
  const [activeStep, setActiveStep] = useState(1);
  const [steps] = useState(["Test", "Location", "Date and Time"]);

  //step 1
  const [category, setCategory] = useState([]);
  const [vaccine, setVaccine] = useState([]);

  //step 2
  const [locationList, setLocationList] = useState([]);
  const [scheduleData, setScheduleData] = useState<any>([]);
  const [selectedDate, setSelectedDate] = useState<any>(null);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState("");
  const [vaccine_schedule_id, setSelectedVaccineScheduleId] = useState(0);

  //user email
  const userData = useSelector<AppState, AppState["Login"]>(({ Login }) => Login);

  // Geocode.setApiKey("AIzaSyAMlwhN-bT8lFurix7Og_pc4R0kjBxHn88");

  //SCHEDULE_INFO
  const [ScheduleInfo] = useState<any>({
    category: "",
    vaccine: "",
    location: "", //providerId
    biz_branch_id: "", //bizBranchId
    date: "",
    time: "",
  });

  const [ScheduleInfoInputs, handleScheduleInfoChange] = useFormFieldsHandleChange(ScheduleInfo);

  const handlesSelectedTimeSlot = async (slot: string, vs_id: any) => {
    // Get the current time
    const currentTime = new Date();

    // Add 1 hour to the current time
    const oneHourLater = new Date(currentTime.getTime() + 1 * 60 * 60 * 1000);

    // Parse the selected slot time into a Date object for comparison
    const selectedTime = new Date(selectedDate);
    const [time, modifier] = slot.split(" "); // "11:00 AM"
    let [hours, minutes] = time.split(":");

    if (modifier === "PM" && hours !== "12") {
      hours = (parseInt(hours, 10) + 12).toString();
    }
    if (modifier === "AM" && hours === "12") {
      hours = "00";
    }

    selectedTime.setHours(parseInt(hours, 10));
    selectedTime.setMinutes(parseInt(minutes, 10));
    selectedTime.setSeconds(0);
    selectedTime.setMilliseconds(0);

    // Check if the selected date is today
    const isToday = currentTime.toDateString() === selectedTime.toDateString();
    if (isToday) {
      // Apply 1-hour rule only if the selected date is today
      if (selectedTime >= oneHourLater) {
        // Allow the selection and update the state
        setSelectedTimeSlot(slot);
        setSelectedVaccineScheduleId(vs_id);
      } else {
        // Alert the user if the time is within the next 1 hour
        setAlertMessage({
          type: "info",
          description: "You cannot select a time slot within the next 1 hour.",
          open: true,
        });
      }
    } else {
      // If the selected date is in the future, allow the selection without 1-hour restriction
      setSelectedTimeSlot(slot);
      setSelectedVaccineScheduleId(vs_id);
    }
  };

  const handleSubmit_category = async (value: string) => {
    let vaccines = await getVaccine({ category_id: value });
    setVaccine(vaccines);
  };

  const getTestCategoryFunc = async () => {
    let categories = await getTestCategory();
    setCategory(categories);
  };

  useEffect(() => {
    getTestCategoryFunc();
    setActiveStep(0);
  }, []);

  const handleStepOne = async () => {
    let locationData = await getLocationData({ vaccine_id: ScheduleInfoInputs.vaccine });
    setLocationList(locationData);
    setActiveStep(1);
  };

  const handleStepTwo = async () => {
    const selectedLocationObj: any = locationList.find((item: any) => item.value === ScheduleInfoInputs.location);
    let data = {
      biz_branch_id: selectedLocationObj.biz_branch_id,
      // vaccine_id: ScheduleInfoInputs.vaccine,
    };
    try {
      const res = await getScheduledData(data);
      setScheduleData(res.data);
      setActiveStep(2);
    } catch (err) {
      console.log(err);
    }
  };
  const [open, setOpen] = useState(false);
  const [appointmentModalData, setAppointmentModalData] = useState<any>({});
  const [alertMessage, setAlertMessage] = useState<AlertType | null>(null);

  const handleAdd = async () => {
    const selectedLocationObj: any = locationList.find((item: any) => item.value === ScheduleInfoInputs.location);
    let data = {
      biz_branch_id: selectedLocationObj.biz_branch_id,
      email: userData.userData.email,
      vaccine_id: ScheduleInfoInputs.vaccine,
      vaccine_schedule_id: vaccine_schedule_id,
      date: selectedDate,
      slot: selectedTimeSlot,
    };
    let res = await bookAppointment(data);

    if (res.message === "Appointment Booked Successfully") {
      setAppointmentModalData({
        vaccine_id: ScheduleInfoInputs.vaccine,
        vaccine: res.data.vaccine,
        biz_branch_id: selectedLocationObj.biz_branch_id,
        provider_biz_name: res.data.provider_biz_name,
        prov_location: res.data.prov_location,
        schedule_appointment_id: res.data.schedule_appointment_id,
        date: getFormattedDate(selectedDate),
        slot: selectedTimeSlot,
      });
      setOpen(true);
    } else {
      setAlertMessage({ type: "info", description: res.message, open: true });
    }
  };

  const [openPopover, setOpenPopover] = useState(false);
  const handleClose = () => {
    setOpenPopover(false);
  };
  const handleOpenPopover = (sData: any) => {
    setSelectedTimeSlot("");
    setSelectedVaccineScheduleId(0);
    setSelectedDate(sData);
    setOpenPopover(true);
  };
  const [show, setShow] = useState(false);
  const dateTime = selectedDate !== null ? ("Your appointment is to be scheduled on " + getFormattedDate(selectedDate) + " at " + selectedTimeSlot).toString() : "";
  const filteredData = selectedDate ? scheduleData.filter((data: any) => data.date === selectedDate) : scheduleData;

  let availableTimeSlots: any = [];
  if (filteredData.length > 0) availableTimeSlots = createTimeSlots(filteredData[0].scheduledVaccines);
  return (
    <div className="outer">
      <DashboardLayout>
        <div className="outer__right overflow-y-auto">
          <div className="outer__right-stepper">
            <div className="flex items-center gap-[5px] cursor-pointer">
              <Back />
              {activeStep === 0 ? (
                <Link to="/home" className="text-[16px] font-[600] text-[#888A8C]">
                  Back
                </Link>
              ) : activeStep === 1 ? (
                <Link to="#" className="text-[16px] font-[600] text-[#888A8C]" onClick={() => setActiveStep(0)}>
                  Back
                </Link>
              ) : (
                <Link to="#" className="text-[16px] font-[600] text-[#888A8C]" onClick={() => setActiveStep(1)}>
                  Back
                </Link>
              )}
            </div>
            <br />
            <h1 className="outer__right-header-heading">Schedule a Diagnostic Test</h1>
            <br />
            <CustomStepper steps={steps} activeStep={activeStep} />
            <br />
            {activeStep === 0 ? (
              <>
                <div className="outer__right-header">
                  <h2 className="text-xl text-[#1F2124] font-bold leading-none">Which category of diagnostic test you would like to recieve?</h2>
                </div>
                <br />
                <div className="grid grid-cols-[1fr_1fr] items-center gap-[50px]">
                  <div className="mt-[10px]">
                    <FromSelect
                      name="category"
                      label="Select Test Category"
                      value={ScheduleInfoInputs.category}
                      required
                      onChange={handleScheduleInfoChange}
                      options={category}
                      onSubmit={handleSubmit_category}
                    />
                  </div>
                </div>
                <br />
                <div className="outer__right-header">
                  <h2 className="text-xl text-[#1F2124] font-bold leading-none">Which diagnostic test would you like to recieve?</h2>
                </div>
                <br />
                <div className="grid grid-cols-[1fr_1fr] items-center gap-[50px]">
                  <div className="mt-[10px]">
                    <FromSelect name="vaccine" label="Select Your Test" value={ScheduleInfoInputs.vaccine} required onChange={handleScheduleInfoChange} options={vaccine} />
                  </div>
                </div>
                <div className="mt-[40px] flex grid grid-cols-2 mb-[20px]">
                  <VAButton text="Next" light onClick={() => handleStepOne()} />
                </div>
              </>
            ) : activeStep === 1 ? (
              <>
                <div className="outer__right-header">
                  <h2 className="text-xl text-[#1F2124] font-bold leading-none">Where would you like to recieve the diagnostic test?</h2>
                  <span className="text-[14px] font-[400] text-[#888A8C] max-w-[300px] pt-[20px] mt[revert]">Only providers listed are offering the diagnostic test you selected</span>
                </div>
                <br />
                <div className="grid grid-cols-[1fr_1fr] items-center gap-[50px]">
                  <div className="mt-[20px]">
                    <FromSelect
                      name="location"
                      label="Select Location"
                      value={ScheduleInfoInputs.location}
                      required
                      onChange={handleScheduleInfoChange}
                      // onBlur={validateErrorScheduleForm}
                      options={locationList}
                      // onSubmit={handleSubmit_location}
                    />
                  </div>
                </div>
                <div className="mt-[40px] flex grid grid-cols-2 mb-[20px]">
                  <VAButton text="Next" light onClick={() => handleStepTwo()} />
                </div>
              </>
            ) : (
              <>
                <div className="outer__right-header">
                  <h2 className="text-xl text-[#1F2124] font-bold leading-none">Select the date and time slot for your appointment?</h2>
                  {/* <p>{selectedDate !== undefined? selectedDate.toString(): null}</p>
                    <p>{selectedTimeSlot !== undefined? selectedTimeSlot : ""}</p> */}
                </div>
                <br />
                <div style={{ display: show === true ? "block" : "none", color: "green" }}>
                  <h3>{dateTime} </h3>
                  <h3>Click Add to confirm!</h3>
                </div>
                <SelectDateAndTime selectedDate={selectedDate} scheduleData={scheduleData} handleOpenPopover={handleOpenPopover} />
                <br />
                <br />

                <Popover open={openPopover} onClose={handleClose} anchorOrigin={{ vertical: "center", horizontal: "center" }} PaperProps={{ style: { width: "35%" } }}>
                  <div style={{ alignContent: "center", margin: "30px" }}>
                    <div className="outer__right-header text-center">
                      <h1 className="outer__right-header-heading">Select the time slot of your appointment?</h1>
                    </div>
                    <br />
                    <div className="flex flex-col md:grid md:items-end md:grid-cols-[6fr] justify-between w-full gap-[10px] mt-[10px] text-center">
                      {selectedDate === undefined || selectedDate === null ? null : (
                        <Grid container spacing={6}>
                          {availableTimeSlots.map((scheduledVaccine: any) => (
                            <Grid item key={scheduledVaccine} xs={4}>
                              <Button
                                variant={selectedTimeSlot === scheduledVaccine.slot ? "contained" : "outlined"}
                                fullWidth
                                onClick={() => {
                                  handlesSelectedTimeSlot(scheduledVaccine.slot, scheduledVaccine.vaccine_schedule_id);
                                }}
                                style={{
                                  backgroundColor: selectedTimeSlot === scheduledVaccine.slot ? "#45818e" : "transparent",
                                  color: selectedTimeSlot === scheduledVaccine.slot ? "white" : "#45818e",
                                }}
                              >
                                {scheduledVaccine.slot}
                              </Button>
                            </Grid>
                          ))}
                        </Grid>
                      )}
                    </div>
                    <br />
                    <VAButton
                      text="OK"
                      light
                      onClick={() => {
                        setOpenPopover(false);
                        setShow(true);
                      }}
                    />
                  </div>
                </Popover>

                <div className="mt-[40px] flex grid grid-cols-2 mb-[20px]">
                  <VAButton text="Add" light onClick={handleAdd} />
                </div>
              </>
            )}
          </div>
        </div>
      </DashboardLayout>
      <AppointmentModal open={open} setOpen={setOpen} appointmentModalData={appointmentModalData} />
      {alertMessage !== null ? <VAAlert alertMessage={alertMessage} setAlertMessage={setAlertMessage} /> : null}
    </div>
  );
};
